'use client';

import * as Styled from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { LinkNewTab } from '../LinkNewTab';

export interface FooterProps {
  gitHubLink: string;
  email: string;
  linkedinLink: string;
  copyright: string;
}

export const Footer = (props: FooterProps) => {
  return (
    <Styled.FooterContainer>
      <Styled.FooterInformation>
        <div>
          <FontAwesomeIcon icon={faGithub} />
          <LinkNewTab
            link={props.gitHubLink}
            text="GitHub"
            key={props.gitHubLink}
          />
        </div>
        <div>
          <FontAwesomeIcon icon={faEnvelope} />
          <p>{props.email}</p>
        </div>
        <div>
          <FontAwesomeIcon icon={faLinkedin} />
          <LinkNewTab
            link={props.linkedinLink}
            text="LinkedIn"
            key={props.linkedinLink}
          />
        </div>
      </Styled.FooterInformation>
      <Styled.FooterCopyright>{props.copyright}</Styled.FooterCopyright>
    </Styled.FooterContainer>
  );
};
