'use client';

import * as Styled from './styled';
import { useRouter } from 'next/navigation';

export interface ButtonProps {
  text: string;
  page: string;
  postId?: number;
}

export const ButtonSideBar = ({ text, page, postId }: ButtonProps) => {
  const router = useRouter();
  const uri = !postId ? page : `${page}/${postId}`;

  return (
    <Styled.ButtonContainer
      type="button"
      onClick={() =>
        router.push(uri, {
          scroll: false,
        })
      }
    >
      {text}
    </Styled.ButtonContainer>
  );
};
