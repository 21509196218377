import styled, { css, DefaultTheme } from 'styled-components';

type MenuBehaviorProps = {
  sideBarVisible: boolean;
};

const buttonMenuVisible = (
  sideBarVisible: MenuBehaviorProps['sideBarVisible'],
) => css`
    @media (min-width: 670px) {
        display: none;
    }

    left: ${sideBarVisible ? '0' : '-27rem'};
    overflow-y: ${sideBarVisible ? 'auto' : 'hidden'};
`;

export const MenuContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['sideBarVisible'].includes(prop),
})<MenuBehaviorProps>`
    ${({ theme, sideBarVisible }) => css`
        @media (min-width: 670px) {
            display: none;
        }

        box-sizing: border-box;
        background: ${theme.colors.dark};
        padding: 2rem;
        display: grid;
        position: fixed;
        justify-content: center;
        align-content: flex-start;
        z-index: 2;
        width: 100%;
        max-width: 28rem;
        height: 100svh;
        top: 0;
        left: 0;
        transition: all 300ms ease-in-out;
        overflow-y: auto;
        ${buttonMenuVisible(sideBarVisible)}
    `}
`;

const buttonOpenOrClose = (
  sideBarVisible: MenuBehaviorProps['sideBarVisible'],
  theme: DefaultTheme,
) => css`
    @media (min-width: 670px) {
        display: none;
    }

    left: ${sideBarVisible ? '24rem' : '1rem'};
    color: ${sideBarVisible ? theme.colors.dark : theme.colors.light};
`;

export const OpenClose = styled.button.withConfig({
  shouldForwardProp: (prop) => !['sideBarVisible'].includes(prop),
})<MenuBehaviorProps>`
    ${({ theme, sideBarVisible }) => css`
        @media (min-width: 670px) {
            display: none;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 2rem;
        color: ${theme.colors.light};
        background: ${theme.colors.dark};
        border: .2rem solid ${theme.colors.light};
        border-radius: 1rem;
        z-index: 3;
        width: 3rem;
        height: 3rem;
        transition: all 300ms ease-in-out;

        svg {
            width: 1.4rem;
            color: ${theme.colors.light}
        }

        ${buttonOpenOrClose(sideBarVisible, theme)}
    `}
`;

export const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    width: 100%;
    margin-top: 4rem; 
    padding-bottom: 1rem;
    position: relative;

    input {
        height: 3rem;
        width: 17rem;
        background: ${({ theme }) => theme.colors.blueDark};
        color: ${({ theme }) => theme.colors.light};
        font-weight: 700;
        border-radius: 0.3rem;
        border: 0;
        border-color: ${({ theme }) => theme.colors.blue};
        padding-left: 1rem;
        outline: none;
        font-weight: 500;
        font-size: 1.2rem;
    }

    input::placeholder {
        color: ${({ theme }) => theme.colors.light};
        cursor: text;
        font-weight: 500;
    }

    button { 
        height: 3rem;
        width: 6rem;
        margin-left: 1rem;
        color: ${({ theme }) => theme.colors.light};
        cursor: pointer;
        border-radius: .5rem;
        border: .5rem;
        font-weight: 500;
        font-size: 1.2rem;
        background: ${({ theme }) => theme.colors.blueDark};

        &::selection {
            background: ${({ theme }) => theme.colors.blue};
            color: ${({ theme }) => theme.colors.light};
            transition: all 300ms ease-in-out;
        }
    }
`;

export const DropDownContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 1rem;
    position: relative;
`;

export const DropdownButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    background: ${({ theme }) => theme.colors.blueDark};
    color: ${({ theme }) => theme.colors.light};
    border: none;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
        background: ${({ theme }) => theme.colors.blue};
    }

    svg {
        margin-left: 0.5rem;
    }
`;

export const DropdownMenu = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    background: ${({ theme }) => theme.colors.dark};
    border-radius: 0.3rem;
    overflow: hidden;
    position: relative;
`;

export const DropdownItem = styled.li`
    padding: 1rem;
    background: ${({ theme }) => theme.colors.blueDark};
    color: ${({ theme }) => theme.colors.light};
    cursor: pointer;
    transition: background 0.3s;
    font-weight: 700 !important;
    font-size: 1rem;

    &:hover {
        background: ${({ theme }) => theme.colors.blue};
    }
`;
