import Image, { StaticImageData } from 'next/image';
import * as Styled from './styled';

export interface LogoLinkProps {
  srcImage: StaticImageData;
  altText: string;
}

export const LogoLink = ({ srcImage, altText }: LogoLinkProps) => {
  return (
    <Styled.Container href="/">
      <Image src={srcImage} alt={altText} priority={true} />
    </Styled.Container>
  );
};
