import { css } from 'styled-components';
import { createGlobalStyle } from 'styled-components';

const styles = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-size: 1.6rem;
    background: #E0E1DD;
  }

  h1, h2, h3, h4, h5, h6 {
  }

  a {
    text-decoration: none;
    color: #415A77;
  }
`;

const GlobalStyles = createGlobalStyle`${styles}`;

export default GlobalStyles;
