import styled from 'styled-components';

export const ButtonContainer = styled.button`
    position: relative;
    width: 24rem;
    height: 3rem;
    height: 3rem;
    color: ${({ theme }) => theme.colors.light};
    cursor: pointer;
    border-radius: .5rem;
    border: .5rem;
    font-weight: 500;
    font-size: 1.2rem;
    background: ${({ theme }) => theme.colors.blueDark};
    margin-bottom: 1rem;

    &::selection {
        background: ${({ theme }) => theme.colors.blue};
        color: ${({ theme }) => theme.colors.light};
        transition: all 300ms ease-in-out;
    }
`;
