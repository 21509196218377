'use client';

import { LogoLink } from '../LogoLink';
import * as Styled from './styled';
import Logo from '../../../public/IPDEV.png';
import { Button } from '../Button';
import { ButtonDropDown } from '../ButtonDropDown';
import { Search } from '../Search';
import { BackendEnum } from '@/enums/backendEnum';
import { getEnumData } from '@/utils/enumHelper';

export const NavBar = () => {
  const categoryList = getEnumData(BackendEnum);

  return (
    <>
      <Styled.NavBar>
        <Styled.LeftContainer>
          <LogoLink altText="IP Dev" srcImage={Logo} />
          <ButtonDropDown
            key={'Backend'}
            buttonName="Backend"
            items={categoryList}
          />
          {/* <ButtonDropDown
            key={'Frontend'}
            buttonName="Frontend"
            items={[{ link: '/', name: 'Next' }]}
          /> */}
          <Button text="Home" key="Home" page="/" />
          <Button text="About" key="About" page="/about" />
        </Styled.LeftContainer>
        <Styled.RightContainer>
          <Search />
        </Styled.RightContainer>
      </Styled.NavBar>
    </>
  );
};
