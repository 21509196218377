import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    margin-right: .5rem;

    input {
        width: 20rem;
        height: 3rem;
        background: ${({ theme }) => theme.colors.dark};
        color: ${({ theme }) => theme.colors.light};
        font-weight: 500;
        font-size: 1.2rem;
        border-radius: 0.3rem;
        border: 0;
        box-shadow: 0 0 0.1rem 0.1rem ${({ theme }) => theme.colors.blue};
        border-color: ${({ theme }) => theme.colors.blue};
        padding-left: 1rem;
        outline: none;
    }

    input::placeholder {
        color: ${({ theme }) => theme.colors.light};
        cursor: text;
    }

    @media (${({ theme }) => theme.media.tabletScreen}) {
        input {
            font-size: 1.2rem;
        }
    }

    @media (${({ theme }) => theme.media.smallTabletScreen}) {
        display: none;
    }
`;
