export interface LinkNewTabProps {
  text: string;
  link: string;
}

export const LinkNewTab = (props: LinkNewTabProps) => {
  return (
    <a href={props.link} target="_blank" rel="noopener noreferrer">
      {props.text}
    </a>
  );
};
