import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    dark: '#0D1B2A',
    blueDark: '#1B263B',
    blue: '#415A77',
    lightBlue: '#778DA9',
    light: '#E0E1DD',
  },
  media: {
    largeMobileScreen: 'max-width: 670px',
    smallTabletScreen: 'max-width: 670px',
    tabletScreen: 'max-width: 768px',
    smallLaptopScreen: 'max-width: 1024px',
    largeLaptopScreen: 'max-width: 1440px',
  },
};

export default theme;
