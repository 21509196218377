import styled from 'styled-components';

export const Container = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.dark};
    color: ${({ theme }) => theme.colors.light};
    cursor: pointer;
    width: 7rem;
    height: 3rem;
    border-radius: .5rem;
    border: .5rem;
    box-shadow: 0 0 0.1rem 0.1rem ${({ theme }) => theme.colors.blue};
    margin-left: 1rem;
    font-weight: 500;
    font-size: 1.2rem;

    &:hover {
      background: ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.light};
      transition: all 300ms ease-in-out;
    }

  @media (${({ theme }) => theme.media.tabletScreen}) {
    font-size: 1.2rem;
    width: 6rem;
  }

  @media (${({ theme }) => theme.media.smallTabletScreen}) {
    width: 6rem;
    height: 2.5rem;
    font-weight: 500 !important;
  }
`;
