import styled from 'styled-components';

export const DropDownContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 1rem;
    position: relative;
`;

export const DropdownButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    background: ${({ theme }) => theme.colors.blueDark};
    color: ${({ theme }) => theme.colors.light};
    border: none;
    border-radius: 0.3rem;
    cursor: pointer;
    font-weight: 500;
    font-size: 1.2rem;
    transition: background 0.3s;

    &:hover {
        background: ${({ theme }) => theme.colors.blue};
    }

    svg {
        margin-left: 0.5rem;
    }
`;

export const DropdownMenu = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    background: ${({ theme }) => theme.colors.dark};
    border-radius: 0.3rem;
    overflow: hidden;
    position: relative;
`;

export const DropdownItem = styled.li`
    padding: 1rem;
    background: ${({ theme }) => theme.colors.blueDark};
    color: ${({ theme }) => theme.colors.light};
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
        background: ${({ theme }) => theme.colors.blue};
    }

    div {
        color: ${({ theme }) => theme.colors.light};
        font-weight: 500;
        font-size: 1.2rem;
    }

`;
