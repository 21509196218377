'use client';

import * as Styled from './styled';
import { useRouter } from 'next/navigation';

export interface ButtonProps {
  text: string;
  page: string;
  uri?: string;
}

export const Button = ({ text, page, uri }: ButtonProps) => {
  const router = useRouter();
  const newUri = uri === null || uri === undefined ? page : `${page}/${uri}`;

  return (
    <Styled.Container
      type="button"
      onClick={() =>
        router.push(newUri, {
          scroll: false,
        })
      }
    >
      {text}
    </Styled.Container>
  );
};
