'use client';

import { PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import StyledComponentsRegistry from '@/app/lib/registry';
import GlobalStyles from './global-styles';
import { CreatorContextProvider } from '@/context/creator/creatorContext';

export default function ApplicationTheme({ children }: PropsWithChildren) {
  return (
    <StyledComponentsRegistry>
      <GlobalStyles />
      <CreatorContextProvider>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </CreatorContextProvider>
    </StyledComponentsRegistry>
  );
}
