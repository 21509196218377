import { CreatorModel } from '@/models/creatorModel';
import { createContext, useContext, useState, ReactNode } from 'react';

interface MyContextProps {
  creatorModel: CreatorModel;
  setCreatorModel: (newCreatorModel: CreatorModel) => void;
}

const CreatorContext = createContext<MyContextProps | undefined>(undefined);

export const CreatorContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [creatorModel, setCreatorModel] = useState<CreatorModel>({
    id: 0,
    isAuth: false,
    token: '',
  });

  return (
    <CreatorContext.Provider value={{ creatorModel, setCreatorModel }}>
      {children}
    </CreatorContext.Provider>
  );
};

export const useCreatorContext = () => {
  const context = useContext(CreatorContext);
  if (!context) {
    throw new Error('useCreatorContext must be use inside a MyContextProvider');
  }
  return context;
};
