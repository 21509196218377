'use client';

import React from 'react';
import * as Styled from './styled';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/navigation';

export interface ButtonDropDownProps {
  buttonName: string;
  items: ButtonChild[];
}

export interface ButtonChild {
  name: string;
  id: number;
}

export const ButtonDropDown = ({
  buttonName,
  items = [],
}: ButtonDropDownProps) => {
  const router = useRouter();
  return (
    <Styled.DropdownContainer>
      <Styled.Container>
        {buttonName}
        <FontAwesomeIcon icon={faChevronDown} />
      </Styled.Container>
      <Styled.DropdownContent>
        {items.map((child, index) => (
          <div
            key={index}
            onClick={() =>
              router.push(`/category/${child.id}`, {
                scroll: false,
              })
            }
          >
            {child.name}
          </div>
        ))}
      </Styled.DropdownContent>
    </Styled.DropdownContainer>
  );
};
