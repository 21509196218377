import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: none;
  color: ${({ theme }) => theme.colors.light};
  cursor: pointer;
  width: 8rem;
  height: 3rem;
  border-radius: 0.3rem;
  border: .5rem;
  box-shadow: 0 0 0.1rem 0.1rem ${({ theme }) => theme.colors.blue};
  margin-left: 1rem;
  font-weight: 500;
  font-size: 1.2rem;

  &:hover {
    background: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.light};
    transition: all 300ms ease-in-out;
  }

  svg {
        color: ${({ theme }) => theme.colors.light};
        padding-left: .5rem;
        width: 1.2rem;
    }

  @media (${({ theme }) => theme.media.tabletScreen}) {
    font-size: 1.2rem;
    width: 8rem;
  }

  @media (${({ theme }) => theme.media.smallTabletScreen}) {
    display: none;
  }
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.blueDark};
  width: 8rem;
  top: 3rem;
  border-radius: 0.5rem;
  border: .5rem;
  box-shadow: 0 0 0.1rem 0.1rem ${({ theme }) => theme.colors.blue};
  margin-left: 1rem;

  div {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.light};
    text-decoration: none;
    padding: 1rem;
    display: block;
    justify-items: center;
    justify-content: center;
    text-align: left;
    font-weight: 500;
    font-size: 1.2rem;
    height: 100%;
    width: 100%;

    &:hover {
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }

  @media (${({ theme }) => theme.media.tabletScreen}) {
      div {
        font-size: 1.2rem; 
      }

      width: 8rem;
    }
`;

export const DropdownContainer = styled.div`
  display: inline-block;
  left: 0;
  border-radius: 0.5rem;
  border: .5rem;
  
  &:hover ${DropdownContent} {
    display: block;
  }
`;
