import styled from 'styled-components';

export const NavBar = styled.nav`
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-around;
    top: 0;
    right: 0;
    left: 0;
    height: 7rem;
    background: ${({ theme }) => theme.colors.dark};
    z-index: 2;

    @media (${({ theme }) => theme.media.tabletScreen}) {
        justify-content: space-between;
    }

    @media (${({ theme }) => theme.media.smallTabletScreen}) {
        justify-content: center;

        button {
            display: none;
        }
    }
`;

export const LeftContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    top: 0;
    left: 0;
    margin-left: 1rem;

    @media (${({ theme }) => theme.media.smallTabletScreen}) {
        margin-left: 0;
    }
`;

export const RightContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    top: 0;
    right: 0;
    margin-right: 1.5rem;
    
    @media (${({ theme }) => theme.media.tabletScreen}) {
    }
`;
