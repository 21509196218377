import styled from 'styled-components';

export const Container = styled.a`
    display: flex;
    align-items: center;

    img {
        width: 9rem;
        height: 3rem;
        margin-left: 1rem;
    }

    @media (${({ theme }) => theme.media.smallTabletScreen}) {
        img {
            margin-left: 1.5rem;
        }
    }
`;
