'use client';

import React, { useState } from 'react';
import * as Styled from './styled';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/navigation';

export interface ButtonDropDownClickProps {
  buttonName: string;
  items: ButtonChild[];
}

export interface ButtonChild {
  name: string;
  id: number;
}

export const ButtonDropDownClick = ({
  buttonName,
  items = [],
}: ButtonDropDownClickProps) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible((visible) => !visible);
  };

  const router = useRouter();

  return (
    <>
      <Styled.DropDownContainer>
        <Styled.DropdownButton onClick={toggleDropdown}>
          {buttonName}{' '}
          <FontAwesomeIcon icon={dropdownVisible ? faCaretUp : faCaretDown} />
        </Styled.DropdownButton>
        {dropdownVisible && (
          <Styled.DropdownMenu>
            {items.map((item, index) => (
              <Styled.DropdownItem key={index}>
                <div
                  onClick={() =>
                    router.push(`/category/${item.id}`, {
                      scroll: false,
                    })
                  }
                >
                  {item.name}
                </div>
              </Styled.DropdownItem>
            ))}
          </Styled.DropdownMenu>
        )}
      </Styled.DropDownContainer>
    </>
  );
};
