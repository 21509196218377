import styled from 'styled-components';

export const FooterContainer = styled.footer`
    display: grid;
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    height: 12rem;
    background: ${({ theme }) => theme.colors.dark};

    a, p {
        color: ${({ theme }) => theme.colors.light};
        display: flex;
        align-items: center;
        font-size: 1.4rem;
    }

    svg {
        width: 2rem;
        height: 2rem;
        color: ${({ theme }) => theme.colors.light};
        margin-right: .5rem;
    }

    @media (${({ theme }) => theme.media.tabletScreen}) {
        height: 12rem;
        padding: .5rem 0;

        a, p {
            font-size: 1.2rem;
        }
    }
`;

export const FooterInformation = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    div {
        display: flex;
        align-items: center;
    }

    @media (${({ theme }) => theme.media.tabletScreen}) {
        flex-direction: column;

        div {
            padding: .3rem 0;
        }
    }
`;

export const FooterCopyright = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
`;
