'use client';

import * as Styled from './styled';
import { faChevronLeft, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { ButtonDropDownClick } from '../ButtonDropDownClick';
import { ButtonSideBar } from '../ButtonSideBar';
import { BackendEnum } from '@/enums/backendEnum';
import { getEnumData } from '@/utils/enumHelper';
import { usePathname, useRouter } from 'next/navigation';

export const SideBar = () => {
  const [sideBarVisible, setSideBarVisible] = useState(false);
  const [input, setInput] = useState('');
  const router = useRouter();
  const pathname = usePathname();

  const handleOpenAndCloseSideBar = (event: React.MouseEvent) => {
    event.preventDefault();
    setSideBarVisible((visible) => !visible);
  };

  const categoryList = getEnumData(BackendEnum);

  useEffect(() => {
    setSideBarVisible(false);
  }, [pathname]);

  return (
    <>
      <Styled.OpenClose
        sideBarVisible={sideBarVisible}
        aria-label="Open or close menu"
        title="Open or close menu"
        onClick={handleOpenAndCloseSideBar}
      >
        {sideBarVisible && <FontAwesomeIcon icon={faChevronLeft} />}
        {!sideBarVisible && <FontAwesomeIcon icon={faBars} />}
      </Styled.OpenClose>
      <Styled.MenuContainer
        sideBarVisible={sideBarVisible}
        aria-hidden={!sideBarVisible}
      >
        <Styled.SearchContainer>
          <input
            type="text"
            value={input}
            placeholder="Search by tags..."
            onChange={(e) => setInput(e.target.value)}
          />
          <button
            type="button"
            onClick={() =>
              router.push(`/search/${input}`, {
                scroll: false,
              })
            }
          >
            Search
          </button>
        </Styled.SearchContainer>
        <ButtonDropDownClick
          buttonName="Backend"
          key="Backend"
          items={categoryList}
        />
        <ButtonSideBar page="/" text="Home" key="Home" />
        <ButtonSideBar page="/about" text="About" key="About" />
      </Styled.MenuContainer>
    </>
  );
};
