export function getEnumData<T extends Record<string, string | number>>(
  enumObj: T,
) {
  return Object.keys(enumObj)
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({
      id: enumObj[key as keyof T],
      name: key,
    }));
}
